<template class="ecohuella">
  <div>
    <hero-global title="EcoHuella Cermi" />
    <section class="w-100 d-block text-center container container-tranparencia-editor-js">
      <EditorJs v-if="entry" :object="entry.body" />
    </section>
  </div>
</template>

<script>
import HeroGlobal from "@/components/Global/HeroGlobal";
import { contentStore } from "@/stores/contents";
import EditorJs from "@/components/Global/editorJs.vue";
export default {
  name: "Clarity",
  components: {
    HeroGlobal,
    EditorJs,
  },
  setup() {
    const store = contentStore();
    return {
      store,
    };
  },
  mounted() {
    this.store.loadPageFixed({
      slug: this.$route.path.replace("/", ""),
    });
  },
  computed: {
    entry() {
      return this.store.getPageFixed(this.$route.path.replace("/", ""));
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/icons";
@import "../styles/general";

.black {
  color: black !important;
  margin-bottom: 0px !important;
}

</style>
